<template>
  <v-app-bar
    color="color1 color1Text--text"
    app
    clipped-left
    fixed
    :prominent="!minibar"
    :shrink-on-scroll="!minibar"
    :fade-img-on-scroll="!minibar"
    :dense="minibar"
  >
    <v-app-bar-nav-icon>
      <v-btn color="color1Text" icon @click.stop="$emit('toggle-drawer')">
        <v-icon>far fa-bars</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>
    <router-link
      v-if="logoUrl"
      to="/"
      tag="img"
      :class="{shown: showLogo}"
      src="https://vblimages.blob.core.windows.net/images/e42f5205-3c6f-45f7-b622-7f0b7f41decd.svg"
      style="height: 100%; max-height: 100%; position: relative"
    >{{logoUrl}}</router-link>
    <template v-slot:img="{ props }" v-if="!minibar && false">
      <v-img
        v-bind="props"
        src="https://vblimages.blob.core.windows.net/images/c6dfde61-9779-431e-9086-9389fac8e6f2.pngx"
        height="100%"
        position="center 80%"
      >
        <scoped-slot-watcher :data="props.height" @change="onPropChange"></scoped-slot-watcher>
        <div class="d-flex align-center justify-center fill-height" style="text-shadow: #000 1px 0 3px;">
          <v-img
            src="https://vblimages.blob.core.windows.net/images/e42f5205-3c6f-45f7-b622-7f0b7f41decd.svg"
            height="100%"
            max-width="80%"
            contain
          >
          </v-img>
        </div>
      </v-img>
    </template>

    <v-spacer></v-spacer>
    <!-- CART FAB  -->
    <cart-fab btn-class="mr-2"></cart-fab>
    <!-- TOURNAMENT QUICK SETTINGS  -->
    <v-fab-transition>
      <quick-settings-dialog v-if="view.admin && tournament"></quick-settings-dialog>
    </v-fab-transition>
    <v-fab-transition>
      <bid-assign-dialog v-if="user && user.bidPrograms && user.bidPrograms.length && tournament"></bid-assign-dialog>
    </v-fab-transition>
    <!-- USER -->
    <template v-if="user">
      <!-- NOTIFICATIONS -->
      <v-fab-transition>
        <notifications v-if="notifications && notifications.length"/>
      </v-fab-transition>
      <!-- USER NAME  -->
      <user-menu></user-menu>
    </template>
    <template v-else>
      <v-btn text color=color1Text @click.stop="$emit('login-click')">
        <v-icon class="mr-2">fas fa-unlock-alt</v-icon>
        Sign In
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import ScopedSlotWatcher from '@/components/Utils/ScopedSlotWatcher'
import { mapGetters } from 'vuex'
const QuickSettingsDialog = () => import('@/components/Tournament/QuickSettings/Dialog')
const BidAssignDialog = () => import('@/components/Tournament/Bids/BidAssignDialog')
const CartFab = () => import('@/components/Utils/CartFab')
const UserMenu = () => import('@/Nav/Toolbars/UserMenu')

export default {
  data () {
    return {
      drawer: null,
      signInDialog: false,
      showLogo: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'cart',
      'organizationName',
      'logoUrl',
      'tournament',
      'view'
    ]),
    minibar () {
      return [
        'tournament-players'
      ].includes(this.$route.name) && this.$vuetify.breakpoint.xsOnly
    },
    headHeight () {
      return this.$vuetify.breakpoint.smAndDown ? 56 : 112
    }
  },
  methods: {
    goToCart () {
      this.$router.push({ name: 'checkout' })
    },
    onPropChange (val) {
      var x = val && val && parseInt(val)
      if (x) {
        this.showLogo = x < 61
      }
    }
  },
  components: {
    CartFab,
    QuickSettingsDialog,
    BidAssignDialog,
    ScopedSlotWatcher,
    UserMenu
  }
}
</script>
<style scoped>
.noW {
  padding: 0;
  min-width: 0;
}
.logo {
  display: none !important;
  height: 48px;
}
.logo.shown {
  display: flex !important;
}
</style>
